<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout row wrap full-height justify-center>
      <v-flex xs12 sm4 pa-3>
        <v-card flat tile color="grey lighten-4" height="100%" v-if="!forgot_password">
          <v-card-title class="pb-0">
            <v-layout justify-center>
              <v-img :src="logo" :lazy-src="logo" max-width="150"/>
            </v-layout>
          </v-card-title>

          <v-card-text class="pt-0">
            <v-form v-model="valid_login" >

              <p class="mb-0"> {{ $ml.get('fields_email') }} </p>
              <v-text-field
                v-model="login.email"
                solo
                required
                class="hidden-shadow"
                :rules="g_email_rules"
                :disabled="g_isLoading"
              ></v-text-field>

               <p class="mb-0"> {{ $ml.get('fields_password') }} </p>
              <v-text-field
                v-model="login.password"
                solo
                min="8"
                counter
                :append-icon="hide_password ? 'visibility' : 'visibility_off'"
                @click:append="() => (hide_password = !hide_password)"
                :type="hide_password ? 'password' : 'text'"
                required
                class="hidden-shadow"
                :rules="g_password_rules"
                :disabled="g_isLoading"
              ></v-text-field>

              <v-layout row wrap justify-center>
                    <v-btn
                      depressed
                      text
                      color="primary"
                      class="lowercase mb-4"
                      @click="forgot_password = true"
                    > ¿Olvidaste tu contraseña? </v-btn>
               </v-layout>


                <v-layout row wrap justify-center>
                    <v-btn
                      depressed
                      color="primary"
                      class="lowercase mb-4"
                      :disabled="!valid_login"
                      @click="authenticate"
                    >{{ $ml.get('general_login') }}</v-btn>
                </v-layout>

            </v-form>

              <v-layout column>
            <v-layout class='pb-4' style="height: 100%; width: 100%" justify-center align-center>
               <p class="font-12 mb-0" style="color: #646464; width:100%; text-align:center; border-bottom: 1px solid #A8A8A8 ; line-height:0.1em; margin:10px 0 20px;"> <span style=" background-color: #F6F6F6; padding:0 10px;">o ingresa con </span> </p>
            </v-layout>
            <v-btn
              depressed
              color="blue darken-3"
              class="lowercase white--text"
              @click="loginSocial('facebook')"
            >{{ $ml.get('login_with_facebook') }}</v-btn>

            <v-btn
              depressed
              color="red darken-3"
              class="lowercase white--text my-3"
              @click="loginSocial('google')"
            >{{ $ml.get('login_with_google') }}</v-btn>

            </v-layout>
          </v-card-text>

          <v-card-actions class="pa-3">
            <v-layout justify-space-around align-center >
              <p class="mb-0"> ¿No tienes cuenta? </p>
              <v-btn color="primary" depressed :to="'/suscribirse'"> Regístrate </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>


        <v-card flat tile color="grey lighten-4" height="100%" v-else>
          <v-card-title class="pb-0">
            <v-layout justify-center>
              <v-img :src="logo" :lazy-src="logo" max-width="150"/>
            </v-layout>
          </v-card-title>

          <v-card-text class="pt-0">
            <v-form v-model="valid_restore" >

              <p class="mb-0"> {{ $ml.get('fields_email') }} </p>
              <v-text-field
                v-model="login.email"
                solo
                required
                class="hidden-shadow"
                :rules="g_email_rules"
                :disabled="g_isLoading"
              ></v-text-field>


              <v-layout column align-center>
                    <v-btn
                      depressed
                      color="primary"
                      class="lowercase "
                      :disabled="!valid_restore"
                      @click="resetPassword"
                    > Recuperar contraseña </v-btn>

                    <v-btn
                      depressed
                      flat
                      color="primary"
                      class="lowercase"
                      @click="forgot_password = false "
                    > Regresar </v-btn>
               </v-layout>




            </v-form>


          </v-card-text>


        </v-card>

      </v-flex>
    </v-layout>

    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
    <message-dialog :dialog="g_dialog"></message-dialog>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'Login',
  metaInfo: {
    title: 'Inicia sesión ',
    meta: [
      { vmid: 'description', name: 'description', content: 'Accede a tu cuenta para ver productos, artículos y más información de tu Ruffus Box.' },
      { vmid: 'og:title', property: 'og:title', content: 'Inicia sesión' },
      { vmid: 'og:url', property: 'og:url', content: 'https://www.ruffusbox.com/iniciar_sesion' },
      { vmid: 'og:image', property: 'og:image', content: 'https://s3-us-west-2.amazonaws.com/media.ruffusbox.com/web/imagen-meta-tags.png' },
      { vmid: 'og:description', property: 'og:description', content: 'Accede a tu cuenta para ver productos, artículos y más información de tu Ruffus Box.' },
      { vmid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: 'Inicia sesión' },
      { vmid: 'twitter:title', property: 'twitter:title', content: 'Inicia sesión' },
      { vmid: 'twitter:image', property: 'twitter:image', content: 'https://s3-us-west-2.amazonaws.com/media.ruffusbox.com/web/imagen-meta-tags.png' },
      { vmid: 'twitter:description', property: 'twitter:description', content: 'Accede a tu cuenta para ver productos, artículos y más información de tu Ruffus Box.' }
    ],
    link: [
      { vmid: 'canonical', rel: 'canonical', href: 'https://www.ruffusbox.com/iniciar_sesion' }
    ]
  },
  data () {
    return {
      valid_restore: false,
      valid_login: false,
      hide_password: true,
      step: 1,
      login: {
        email: '',
        password: ''
      },
      logo: require('../assets/img/brand/logo.png'),
      forgot_password: false
    }
  },
  methods: {
    authenticate () {
      this.$auth(this.login, '/perfil')
    },
    loginSocial (source) {
      this.g_isLoading = true
      var provider = null
      if (source === 'google') provider = new firebase.auth.GoogleAuthProvider()
      if (source === 'facebook') provider = new firebase.auth.FacebookAuthProvider()

      firebase.auth().signInWithPopup(provider).then((result) => {
        let auth = {
          token: result.credential.accessToken,
          uid: result.user.providerData[0].uid
        }

        this.$api.authSocial(
          auth,
          source,
          response => {
            this.$store.dispatch('authenticate', {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token
            })
            this.$goTo('/envio', 'subscribe_create_account_' + source)
          }, response => this.$errorHandling(response).then(() => {
            this.loginSocial(source)
          }, () => {})
        )
      }).catch((error) => {
        this.g_isLoading = false
        this.g_dialog = {
          show: true,
          title: this.$ml.get('general_dialog_error'),
          message: error.message
        }
      })
    },
    resetPassword () {
      this.g_isLoading = true
      this.$api.resetPassword(
        this.login,
        response => {
          this.g_dialog = {
            title: 'Éxito',
            message: 'Revisa tu correo para poder recuperar tu contraseña',
            show: true
          }
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.resetPassword()
        }, () => {})
      )
    }
  }
}
</script>

<style scoped>
</style>
